import { OrgId, OrgInvoiceId, OrgRegistrationQuestion, OrgRegistrationQuestionId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import _ from "lodash";

export async function orgRegistrationAnswer__client__getAnswersForOrgInvoiceRegistration(p: {
  orgInvoiceId: OrgInvoiceId;
  orgId: OrgId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const orgRegistration = await h.OrgRegistration.query({
    where: [
      {
        orgInvoiceId: ["==", p.orgInvoiceId]
      },
      {
        orgId: ["==", p.orgId]
      }
    ],
    limit: 1
  });

  if (orgRegistration.docs.length && orgRegistration.docs[0].answerIds) {
    return _.compact(await h.OrgRegistrationAnswer.getDocs(Object.keys(orgRegistration.docs[0].answerIds)));
  }
  return [];
}
