import {
  OrgId,
  OrgInvoiceId,
  OrgRegistrationAnswerAdditionalStep,
  OrgRegistrationId,
  OrgRegistrationQuestion,
  OrgRegistrationQuestionId,
  OrgRegistrationQuestionType,
  OrgRegistrationQuestion__AdditionalStep
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import { getOrgRegistrationQuestionInfoForRegistrationPackage } from "../orgInvoice/org-invoice-helpers";

export async function orgRegistrationAnswer__client__completeAdditionalStepAnswer(p: {
  answer: Omit<OrgRegistrationAnswerAdditionalStep, "id">;
  orgRegistrationId: OrgRegistrationId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const orgRegistration = await h.OrgRegistration.getDoc(p.orgRegistrationId);

  if (!orgRegistration) {
    throw new Error("Can't find registration");
  }

  const orgRegistrationPackage = await h.OrgRegistrationPackage.getDoc(orgRegistration.orgRegistrationPackageId);
  if (!orgRegistrationPackage) {
    throw new Error("Can't find package");
  }

  const [questionsData, answerData] = await Promise.all([
    getOrgRegistrationQuestionInfoForRegistrationPackage(orgRegistrationPackage),
    h.OrgRegistrationAnswer.getDocs(Object.keys(orgRegistration.answerIds ?? {}))
  ]);
  const answers = _.compact(answerData);

  const additionalSteps = questionsData.orgRegistrationQuestions.filter(
    q => q.type === OrgRegistrationQuestionType.additionalStep
  ) as OrgRegistrationQuestion__AdditionalStep[];

  const hasOtherAdditionalStepsOutstanding = !!additionalSteps.find(
    q => q.id !== p.answer.questionId && !answers.find(ans => ans.questionId === q.id)
  );

  let answerId = h.OrgRegistrationAnswer.generateId();
  const batchTasks = [
    await h.OrgRegistrationAnswer.add(
      {
        doc: { ...p.answer, id: answerId }
      },
      { returnBatchTask: true }
    ),
    await h.OrgRegistration.update(
      {
        id: p.orgRegistrationId,
        doc: {
          answerIds: { [answerId]: true },
          completedAllAdditionalStepsAtMS: hasOtherAdditionalStepsOutstanding ? 0 : Date.now()
        }
      },
      { returnBatchTask: true }
    )
  ];

  await h._BatchRunner.executeBatch(batchTasks);
}
