import {
  OrgId,
  OrgInvoiceId,
  OrgRegistration,
  OrgRegistrationAnswerAdditionalStep,
  OrgRegistrationId,
  OrgRegistrationQuestion,
  OrgRegistrationQuestionId,
  OrgRegistrationQuestionType,
  OrgRegistrationQuestion__AdditionalStep
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import { getOrgRegistrationQuestionInfoForRegistrationPackage } from "../orgInvoice/org-invoice-helpers";
import { BatchTask } from "@ollie-sports/firebase";

export async function orgRegistrationAnswer__client__undoAdditionalStepAnswer(p: {
  answerId: string;
  orgRegistrationId: OrgRegistrationId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const orgRegistration = await h.OrgRegistration.getDoc(p.orgRegistrationId);

  if (!orgRegistration) {
    throw new Error("Can't find registration");
  }

  const batchTasks: BatchTask[] = [];

  const updatedRegistration: Partial<OrgRegistration> = {
    answerIds: { [p.answerId]: h._MagicDeleteValue }
  };

  if (orgRegistration.completedAllAdditionalStepsAtMS > 0) {
    updatedRegistration.completedAllAdditionalStepsAtMS = 0;
  }

  batchTasks.push(
    await h.OrgRegistration.update(
      {
        id: orgRegistration.id,
        doc: updatedRegistration
      },
      {
        returnBatchTask: true
      }
    )
  );

  batchTasks.push(await h.OrgRegistrationAnswer.delete({ id: p.answerId }));

  await h._BatchRunner.executeBatch(batchTasks);
}
